



























































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BPagination,
  BButton, BCol, BRow, BCard, BInputGroup, BCardTitle, BForm, BCardText, BFormSelectOption, BFormTag, BFormTags
} from "bootstrap-vue";
import {VForm} from '@/global';


@Component({
  components: {
    BCardText,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BFormSelectOption,
    BFormTags
  }
})
export default class ReportGenerator extends Vue {
  $refs!: {
    form: VForm
  }

  item: ReportingAPI.Generators.Item = null;
  templates: ReportingAPI.Templates.Item[] = [];
  branches: [] = [];
  flows: RegistrationsAPI.Flows.FlowCollection = null;

  @Watch("id", {immediate: true})
  async watchId() {
    await this.getFormData();
    await this.getItem();
  }

  get id() {
    return this.$route.params.identifier;
  }

  editing = false;

  get isEditing() {
    return (this.id == "create" || this.editing)
  }

  async getFormData() {
    this.$set(this, 'templates', await this.$api.reporting.templates.GetList());
    this.$set(this, 'flows', await this.$api.registrations.flows.GetFlows());
  }

  async getItem() {
    if (this.id == "create") {
      this.$set(this, "item", {
        "name": ""
      });

      return;
    }

    const item = await this.$api.reporting.generators.Get(this.id);
    this.$set(this, "item", item);
  }

  async saveChanges() {
    if (!await this.$refs.form.validate()) return;

    if (this.editing)
      return await this.update();

    if (this.id == "create")
      return await this.create();
  }

  async update() {
    const res = await this.$api.reporting.generators.Update(this.item.id, {...this.item});
    this.editing = false;
    this.$set(this, "item", res);
  }

  async create() {
    const res = await this.$api.reporting.generators.Create({...this.item});
    if (res != null) {
      await this.$router.push({
        name: this.$route.name,
        params: {
          identifier: `${res.id}`
        }
      })
    }
  }
/*
  newQuestion = false;
  nextQuestionColName: string = null;
  nextQuestionId: string = null;

  updateQuestionValue(index, $event) {
    this.item.questions[index] = $event;
  }

  updateIndexValue(index, $event) {
    const value = this.item.questions[index];
    this.removeItem(index);
    this.item.questions[$event] = value;
  }

  repeatAgain() {
    this.$set(this.item.questions, "", null)
  }

  removeItem(index) {
    this.$delete(this.item.questions, index);
  }*/
}
